// Function to replace all instances of a substring
String.prototype.replaceAll = function(str1, str2, ignore) 
{
    return this.replace(new RegExp(str1.replace(/([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g,"\\$&"),(ignore?"gi":"g")),(typeof(str2)=="string")?str2.replace(/\$/g,"$$$$"):str2);
}


function getCleanData(data){
	data = data.replace(/(\r\n|\n|\r)/gm,"");
	data = data.replace(/(\t)/gm," ");
	var tmp = document.createElement("div");
	tmp.innerHTML = data
	var cleanedText = tmp.textContent || tmp.innerText || "";
	var backtickedText = cleanedText.replace(/, full_html/g, "");
	console.log(backtickedText)
	return backtickedText;
}