const API_KEY = "AIzaSyBzjRcwL8W2oMVw2xWSJ1HaEOKPwbdMF3Y";
const REMOTE_DATA =
  "https://web.wpi.edu/webapps/project-center-map/project_centers.json";
/*
  LIST OF PARAMETERS
  - param -------   - data -   - effect ------------------------
	show_sidebar			boolean		 Show sidebar if true
  show_markers			boolean		 Show markers if true
  show_topbar				boolean		 Show topbar if true
  logged_in         boolean    Show additional info for students
	style					    int			   Styled if present
  active_location		string	 	 ID of active location
*/

//Load Data (clean)
var sData;
var data;

// flattening and global variable for filtering
var checksType = [];
var checksTerm = [];
var checksRegion = [];

// Global variable to hold map
var map;
var globalLocationList;
var globalMarkerList = [];
var activeLocationI;

// Global Variable to hold parameters of current map instance
var parameters;
var style;

var xhr = new XMLHttpRequest();
xhr.open("GET", REMOTE_DATA);
xhr.onload = function() {
  if (xhr.status === 200) {
    data = xhr.responseText;
    data = JSON.parse(data);
    data = data[0].entity;

    flattenData("term");
    flattenData("project_type");
    flattenData("region");

    globalLocationList = data;

    initMap();
  } else {
    alert("Request failed.  Returned status of " + xhr.status); //default to static
  }
};
xhr.send();

/*
	Init map function to parse parameters and build map dynamically
*/
function initMap() {
  parameters = parseParameters();

  if (parameters.style) {
    // styled if ==1, default if false
    style = styleMap(parameters.style);
  }
  // Iterate through parameters and build
  if (parameters.active_location) {
    // Get JSON of active location as activeLocation
    var activeLocation = findLocation(parameters.active_location);
    createLocalMap(activeLocation);
  } else {
    //default - world map with everything open
    createWorldMap();
  }
  // Create markers
  if (parameters.show_markers) {
    // make if true, otherwise dont
    if (parameters.show_markers == "true") {
      createMarkers();
    }
  } else {
    //default - world map with everything open
    //OR local map with ONLY markers
    createMarkers();
  }

  // Create sidebar
  if (parameters.show_sidebar) {
    // make if true, otherwise dont
    if (parameters.show_sidebar == "true") {
      createSidebar();
    }
  } else {
    //default - world map with everything open
    //OR local map with nothing
    if (parameters.active_location) {
      // Local
    } else {
      //world
      createSidebar();
    }
  }

  // Create sidebar
  if (parameters.show_topbar) {
    // make if true, otherwise dont
    if (parameters.show_topbar == "true") {
      createTopbar();
    }
  } else {
    //default - world map with everything open
    //OR local map with nothing
    if (parameters.active_location) {
      // Local
    } else {
      //world
      createTopbar();
    }
  }

  // Show additional information to logged in students
  if (parameters.logged_in) {
    //ensure presence before check
    if (parameters.logged_in == "true") {
      // check whether to display extra information for students
      // do something
    }
  }

  // Let everything load and then display
  setTimeout(finishedLoad, 600);

  updateChecks();
}

/*
	Function to build location list on startup
	Needed for glitchy data without fields
*/

// function buildLocationList() {
//     for (var i = 0; i < data.length; i++) {
//         // Check if already made
//         if (data[i].latitude && data[i].longitude) {
//             globalLocationList.push(data[i])
//         }

//     }
// }

/*
	Parse through data to find activeLocation based on id passed
*/
function findLocation(activeLocation) {
  var location = globalLocationList.find(o => o.id === activeLocation);
  return location;
}

/*
	Function which retrieves the URL passed to the iframe and accordingly parses through and returns an object with all parameters
*/
function parseParameters() {
  var parameters = {};
  var url = window.location.search.substring(1); //get rid of "?" in querystring
  if (url != "") {
    // URL was passed
    var parameterArray = url.split("&"); //get key-value pairs
    for (var i = 0; i < parameterArray.length; i++) {
      var parameterKeyValue = parameterArray[i].split("="); //split key and value
      parameters[
        parameterKeyValue[0].toLowerCase()
      ] = parameterKeyValue[1].replaceAll("+", " "); //put in spaces where they should be
    }
  } else {
    // Nothing passed
  }
  //We need to check if this exists
  if (parameters.active_location) {
    var hasFoundId = false; //have we found this id?
    for (
      var i = 0;
      i < globalLocationList.length;
      i++ //loop through all locations
    ) {
      var tempObject = globalLocationList[i]; //get the object here
      if (tempObject.id === parameters.active_location) {
        //does the location id match the active_location id?
        hasFoundId = true; //if yes we are all good
      }
    }
    if (!hasFoundId) {
      //if we have not found id, that means it is an invalid id! remove it from parameters so other functions dont get sad
      delete parameters.active_location; //remove active_location parameter
    }
  }
  return parameters;
}

// Functions for dynamically building the iFrame components

/*
	Create world map
*/
function createWorldMap() {
  // Breakpoints for zoom
  var z;
  var ui;
  if (window.innerWidth <= 800 || window.innerHeight <= 600) {
    z = 2;
    ui = true;
  } else {
    z = 3;
    ui = false;
  }

  var mapStyle = [
    {
      featureType: "transit.station",
      elementType: "labels",
      stylers: [{ visibility: "off" }]
    },
    {
      featureType: "transit.line",
      elementType: "labels",
      stylers: [{ visibility: "off" }]
    },
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [{ visibility: "off" }]
    },
    {
      featureType: "water",
      elementType: "labels",
      stylers: [{ visibility: "off" }]
    },
    {
      featureType: "road",
      stylers: [{ visibility: "off" }]
    }
  ];

  map = new google.maps.Map(document.getElementById("map"), {
    center: {
      lat: 0,
      lng: 0
    },
    zoom: z,
    minZoom: 2,
    maxZoom: 18,
    streetViewControl: false,
    mapTypeControl: !ui,
    mapTypeControlOptions: {
      style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: google.maps.ControlPosition.TOP_CENTER
    },
    disableDefaultUI: ui,
    styles: style
  });
  map.set("styles", mapStyle);
}

/*
	Create local map based on activeLocation object. Zoom on active Location
*/
function createLocalMap(activeLocation) {
  // Code to get lat/long
  var lat;
  var long;
  if (activeLocation.latitude && activeLocation.longitude) {
    lat = parseFloat(activeLocation.latitude);
    long = parseFloat(activeLocation.longitude);
  } else {
    lat = 10;
    long = 10;
  }

  map = new google.maps.Map(document.getElementById("map"), {
    center: {
      lat: lat,
      lng: long
    },
    zoom: 4,
    disableDefaultUI: true,
    styles: style
  });
}

/*
	Create list of all markers. Call setMarker method with array of markers to set them on map based on lat/lng values
*/
function createMarkers() {
  var markerList = [];
  globalLocationList.forEach(function(location) {
    var url =
      "https://www.wpi.edu/project-based-learning/project-based-education/global-project-program/project-centers/" +
      location.label.replace(/[\W_]+/g, "-").toLowerCase();
    var marker = [];
    var lat;
    var long;
    if (location.latitude && location.longitude) {
      lat = parseFloat(location.latitude);
      long = parseFloat(location.longitude);
    } else {
      // Don't add to list of markers if there's no geo location
      return;
    }
    marker.push(location.id);
    marker.push(lat);
    marker.push(long);
    marker.push(location.location_and_culture);
    marker.push(location.project_opportunities);
    marker.push(location.label);
    marker.push("https://eprojects.wpi.edu" + location.images_scaled_480_x_480);
    marker.push(location.project_type);
    marker.push(url);
    markerList.push(marker);
  });
  setMarkers(markerList);
}

function setMarkers(markerList) {
  var mw = 250;
  // shift down by height/4 due to smaller window size
  var y_offset = window.innerHeight / 4;

  // Globally make the info boxes
  var options_click = {
    closeBoxURL: "assets/close.png",
    closeBoxMargin: "5px -40px 0px 0px",
    closeBoxTitle: "Hide info",
    pixelOffset: new google.maps.Size(-(mw / 2), -42),
    maxWidth: mw,
    alignBottom: true
  };

  var ib_click = new InfoBox(options_click);
  var options_hover = {
    closeBoxURL: "",
    pixelOffset: new google.maps.Size(15, -50)
  };

  var ib_hover = new InfoBox(options_hover);
  for (var i = 0; i < markerList.length; i++) {
    // Check if already made
    if (globalMarkerList.some(e => e.data_title == markerList[i][0])) {
      // Already in, do nothing
    } else {
      var location = markerList[i];
      // check type and add marker
      var icon_img = {
        url: "assets/pin.png",
        scaledSize: new google.maps.Size(32, 32) // scaled size
      };

      // Create and push markers
      var marker = new google.maps.Marker({
        position: {
          lat: location[1],
          lng: location[2]
        },
        map: map,
        info: location[3],
        icon: icon_img,
        data_title: location[0],
        data_name: location[5],
        data_img: location[6],
        data_type: location[7],
        data_url: location[8]
      });

      // Push to global list

      if (parameters.active_location == marker.data_title) {
        activeLocationI = globalMarkerList.length;
      }
      globalMarkerList.push(marker);

      // Animate "drop" on appear
      marker.setAnimation(4);

      // Marker click behavior
      google.maps.event.addListener(marker, "click", function() {
        ib_click.close();
        // Check if sidebar opened or on mobile
        // Center shifted
        map.panTo(this.position);
        var content = document.getElementById("content");
        if (content.classList.contains("sidebar-active")) {
          map.panBy(-150, 0);
        }

        if (mw < 400) {
          // shift down by height/4
          map.panBy(0, -1 * y_offset);
        }
        var isActive = false; //variable telling us if this marker is the active_location parameter
        if(parameters.active_location == this.data_title)
        {
          isActive = true;
        }
        var content = createInfoContent(
          this.data_img,
          this.data_name,
          this.info,
          this.data_url,
          isActive
        );

        ib_click.setContent(content);
        ib_click.open(map, this);
      });

      // Map click and drag behaviors
      google.maps.event.addListener(map, "click", function() {
        ib_click.close();
      });
      google.maps.event.addListener(map, "drag", function() {
        ib_click.close();
      });

      // On hover behavior of markers
      google.maps.event.addListener(marker, "mouseover", function() {
        var large_icon_img = {
          url: "assets/pin.png",
          scaledSize: new google.maps.Size(50, 50) // scaled size
        };
        this.setIcon(large_icon_img);

        // Name infowindow
        ib_hover.setContent(createName(this.data_name));
        ib_hover.open(map, this);
      });

      google.maps.event.addListener(marker, "mouseout", function() {
        this.setIcon(icon_img);
        ib_hover.close();
      });
    }
  }
  // var markerCluster = new MarkerClusterer(map, globalMarkerList, {imagePath: './assets/images/m', minimumClusterSize: 3});
}

function createInfoContent(img, data_title, info, url, active) {
  var learnMore = `<a href="${url}" class="learn-more" target="_blank">Learn More</a>`;
  var imageBox = `<div class="infobox-img" style="background-image:url('${img}')"></div>`;
  var ib_click = `<div class="ib-click">`;
  if(active) //If this is active_location in URL parameter, do not show "Learn More" box, otherwise do!
  {
    learnMore = ``; //remove the Learn More box from specific marker
    imageBox = ``;
    ib_click = `<div class="ib-click-noimg">`;
  }
  if (info) {
    info =
      info.substring(0, 250) +
      `...&nbsp;` + learnMore;
    var contentString = ib_click +
								+ imageBox +
								`<div class="infobox-details">
									<h1 class="infobox-title">${data_title}</h1>
									<p class="info-text">${info}</p>
								</div>
							</div>`;
  } else {
    var contentString = ib_click
								 + imageBox +
								`<div class="infobox-details">
									<h1 class="infobox-title">${data_title}</h1>` + learnMore +
								`</div>
							</div>`;
  }

  return contentString;
}

function createName(data_title) {
  var contentString = `<div class="ib-hover">
								<p>${data_title}</p>
						</div>`;

  return contentString;
}

/*
	Create Sidebar and add event listeners on the button, map (for panning).
*/
function createSidebar() {
  var content = document.getElementById("content");
  document.getElementById("sidebar-container").style.display = "flex";
  content.classList.toggle("sidebar-active");
  // Start with sidebar opened, so map shifted
  map.panBy(-125, 0);

  var toggleSidebar = document.getElementById("toggle-sidebar");

  if (window.innerWidth <= 800) {
    // If mobile, start with sidebar closed
    content.classList.toggle("sidebar-active");
    map.panBy(125, 0);
    toggleSidebar.innerHTML = "|||";
  }

  // if sidebar is made, creat toggle button and add listener
  toggleSidebar.style.display = "block";
  toggleSidebar.addEventListener("click", function() {
    content.classList.toggle("sidebar-active");

    if (content.classList.contains("sidebar-active")) {
      // Hide
      map.panBy(-125, 0);
      toggleSidebar.innerHTML = "&lt;&lt;";
    } else {
      // Show
      map.panBy(125, 0);
      toggleSidebar.innerHTML = "|||";
    }
  });

  var check = document.getElementsByName("filters");
  for (let i = 0; i < check.length; i++) {
    const item = check[i];
    item.addEventListener("click", function() {
      updateData(this.dataset.type, this.value, this.checked);
    });

  }
}

/*
	Create Sidebar and add event listeners on the button, map (for panning).
*/
function createTopbar() {
  document.getElementById("topbar").style.display = "block";

  // edit sidebar height if making topbar
  var sidebar = document.getElementById("sidebar-container");
  sidebar.style.height = "calc(100% - 50px)";
  sidebar.style.top = "50px";
}

/*
	Function to hide loading circle and show map
*/
function finishedLoad() {
  document.getElementById("loader").style.display = "none";
  document.getElementById("content").style.display = "block";

  // default local map behavior
  if (parameters.active_location) {
    // Show details on start
    google.maps.event.trigger(globalMarkerList[activeLocationI], "click");
  }
}

/*
-----------------Filtering Behavior------------------
*/

// Delete markers from globalMarkerList that are not in GlobalLocationList
function deleteMarkers() {
  for (var i = globalMarkerList.length - 1; i >= 0; i--) {
    if (globalLocationList.some(e => e.id == globalMarkerList[i].data_title)) {
      // Exists, do nothing
    } else {
      // Not in location list
      // Remove from map
      globalMarkerList[i].setMap(null);

      // Remove from MarkerList
      globalMarkerList.splice(i, 1);
    }
  }
}

// Update data method updates the checkboxes and does an && comparison
// across the different filters. For any new filters-
// add '&&arrayCompareSome(checks<nameArray>, location['filter])'
function updateData(key, value, checked) {
  // Make edits to globalMarkerList accordingly

  // create array of checked values
  updateChecks();

  globalLocationList = [];

  // data.forEach(function (location) {
  //     if (arrayCompareSome(checksType, location['filter']) && arrayCompareSome(checksTerm, location['filter']) && arrayCompareSome(checksRegion, location['filter'])) {
  //         globalLocationList.push(location)
  //     }
  // })

  data.forEach(function(location) {
    if (
      arrayCompareSome(checksType, location["filter"]) &&
      arrayCompareSome(checksTerm, location["filter"]) &&
      arrayCompareSome(checksRegion, location["filter"])
    ) {
      globalLocationList.push(location);
    }
  });

  // Delete unnecessary markers
  deleteMarkers();

  // Create new markers as needed
  createMarkers();
}

/*
---------------Helper Functions----------------------------------
*/
// Take two arrays and return true if array 1 contains ANY elements of array 2
// Return false otherwise
function arrayCompareSome(arr1, arr2) {
  var check = function(ele) {
    if (arr1.indexOf(ele.toUpperCase()) > -1) {
      return true;
    }
  };
  return arr2.some(check);
}

// Accept a key to pull from nested into main level
// Add to key 'filter' on main level
function flattenData(key) {
  var val = [];

  data.forEach(function(location) {
    if (!("filter" in location)) {
      location["filter"] = [];
    }
    Object.keys(location).forEach(i_key => {
      if (i_key == key) {
        location[i_key] = location[i_key].replace(/\&amp;/g, "&");
        val.push(location[i_key].replace(/\&amp;/g, "&"));
      } else if (
        typeof location[i_key] === "object" &&
        Object.keys(location[i_key]).length > 0
      ) {
        var temp = getVal(location[i_key], key);
        if (temp) {
          val.push(...temp);
        }
      }
    });
    location["filter"].push(...val);
    val = [];
  });
}

// Helper function to flatten json through recursion
// TODO: multiple of the same keys
function getVal(obj, key) {
  var val = [];
  Object.keys(obj).forEach(i_key => {
    if (typeof obj[i_key] === "object" && Object.keys(obj[i_key]).length > 0) {
      var temp = getVal(obj[i_key], key);
      if (typeof temp !== 'undefined') {
        val.push(...temp);
      } else {
        // do nothing
      }
    } else if (
      key.localeCompare(i_key, "en", {
        sensitivity: "base"
      }) == 0
    ) {
      val.push(obj[i_key]);
    } else {
      //do nothing
      return;
    }
  });
  if (val.length > 0) {
    return val;
  } else {
    return;
  }
}

// Updates the global variable 'checks' based on the currently checked items
function updateChecks() {
  checksType = [];
  checksTerm = [];
  checksRegion = [];
  var checkboxes = document.querySelectorAll("[data-type='project_type']");
  checkboxes.forEach(function(input) {
    if (input.checked == true) {
      checksType.push(input.id.toUpperCase());
    }
  });

  var checkboxes = document.querySelectorAll("[data-type='term']");
  checkboxes.forEach(function(input) {
    if (input.checked == true) {
      checksTerm.push(input.id.toUpperCase());
    }
  });

  var checkboxes = document.querySelectorAll("[data-type='region']");
  checkboxes.forEach(function(input) {
    if (input.checked == true) {
      checksRegion.push(input.value.toUpperCase());
    }
  });
}

/*
	MAP STYLES
*/

//This function accepts a style (int) and creates and returns a style sheet.
// 1 = white and red
// 2 = WPI.edu bottom embed map

function styleMap(style) {
  if (style == 1) {
    var styles = [
      {
        elementType: "labels",
        stylers: [
          {
            visibility: "off"
          }
        ]
      },
      {
        featureType: "administrative",
        elementType: "geometry",
        stylers: [
          {
            visibility: "off"
          }
        ]
      },
      {
        featureType: "administrative.country",
        elementType: "geometry.stroke",
        stylers: [
          {
            color: "#fdffff"
          },
          {
            visibility: "on"
          },
          {
            weight: 0.5
          }
        ]
      },
      {
        featureType: "administrative.country",
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "on"
          }
        ]
      },
      {
        featureType: "administrative.country",
        elementType: "labels.text",
        stylers: [
          {
            color: "#fdffff"
          },
          {
            lightness: -10
          }
        ]
      },
      {
        featureType: "administrative.land_parcel",
        stylers: [
          {
            visibility: "off"
          }
        ]
      },
      {
        featureType: "administrative.neighborhood",
        stylers: [
          {
            visibility: "off"
          }
        ]
      },
      {
        featureType: "landscape",
        stylers: [
          {
            color: "#c30f2f"
          }
        ]
      },
      {
        featureType: "poi",
        stylers: [
          {
            visibility: "off"
          }
        ]
      },
      {
        featureType: "road",
        stylers: [
          {
            visibility: "off"
          }
        ]
      },
      {
        featureType: "road",
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off"
          }
        ]
      },
      {
        featureType: "transit",
        stylers: [
          {
            visibility: "off"
          }
        ]
      },
      {
        featureType: "water",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#fdffff"
          }
        ]
      }
    ];
  } else if (style == 2) {
    styles = [
      {
        elementType: "geometry.stroke",
        stylers: [
          {
            visibility: "off"
          }
        ]
      },
      {
        elementType: "labels.text",
        stylers: [
          {
            visibility: "off"
          }
        ]
      },
      {
        featureType: "administrative",
        elementType: "geometry.stroke",
        stylers: [
          {
            color: "#cccbcb"
          }
        ]
      },
      {
        featureType: "administrative.country",
        elementType: "geometry.stroke",
        stylers: [
          {
            color: "#cccbcb"
          }
        ]
      },
      {
        featureType: "landscape",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#cccbcb"
          }
        ]
      },
      {
        featureType: "landscape",
        elementType: "geometry.stroke",
        stylers: [
          {
            color: "#cccbcb"
          }
        ]
      },
      {
        featureType: "water",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#333333"
          }
        ]
      }
    ];
  }

  return styles;
}
