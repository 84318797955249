/// <reference types="google.maps" />
const REMOTE_DATA = "https://web.wpi.edu/webapps/project-center-map/project_centers.json";
import { InfoBox } from "./infobox.js";
let sData = [];
let data;
// Flattening and global variables for filtering
let checksType = [];
let checksTerm = [];
let checksRegion = [];
// Global variables
let map;
let globalLocationList = [];
let globalMarkerList = [];
let markerDataMap = new Map();
let activeLocationI;
// Global variable to hold parameters of current map instance
let parameters;
let style;
const xhr = new XMLHttpRequest();
xhr.open("GET", REMOTE_DATA);
xhr.onload = function () {
    if (xhr.status === 200) {
        data = JSON.parse(xhr.responseText);
        data = data[0].entity;
        flattenData("term");
        flattenData("project_type");
        flattenData("region");
        globalLocationList = data;
        initMap();
    }
    else {
        alert(`Request failed. Returned status of ${xhr.status}`);
    }
};
xhr.send();
function initMap() {
    parameters = parseParameters();
    if (parameters.style) {
        style = styleMap(parameters.style);
    }
    const activeLocation = parameters.active_location ? findLocation(parameters.active_location) : undefined;
    const activeLocationI = activeLocation ? globalLocationList.indexOf(activeLocation) : undefined;
    if (activeLocation) {
        createLocalMap(activeLocation);
    }
    else {
        createWorldMap();
    }
    if (parameters.show_markers === "true" || parameters.show_markers === undefined) {
        createMarkers();
    }
    if (parameters.show_sidebar === "true" || parameters.show_sidebar === undefined) {
        createSidebar();
    }
    if (parameters.show_topbar === "true" || parameters.show_topbar === undefined) {
        createTopbar();
    }
    if (parameters.logged_in === "true") {
        // Show additional info for logged-in students
    }
    setTimeout(finishedLoad, 600);
    updateChecks();
}
function findLocation(activeLocation) {
    return globalLocationList.find(o => o.id === activeLocation);
}
function parseParameters() {
    const parameters = {};
    const url = window.location.search.substring(1);
    if (url) {
        const parameterArray = url.split("&");
        for (const param of parameterArray) {
            const [key, value] = param.split("=");
            if (key && value) {
                parameters[key.toLowerCase()] = value.replace(/\+/g, " ");
            }
        }
    }
    if (parameters.active_location) {
        const hasFoundId = globalLocationList.some(loc => loc.id === parameters.active_location);
        if (!hasFoundId) {
            delete parameters.active_location;
        }
    }
    return parameters;
}
function createWorldMap() {
    let z;
    let ui;
    if (window.innerWidth <= 800 || window.innerHeight <= 600) {
        z = 2;
        ui = true;
    }
    else {
        z = 3;
        ui = false;
    }
    const mapStyle = [
        {
            featureType: "transit.station",
            elementType: "labels",
            stylers: [{ visibility: "off" }]
        },
        {
            featureType: "transit.line",
            elementType: "labels",
            stylers: [{ visibility: "off" }]
        },
        {
            featureType: "poi",
            elementType: "labels",
            stylers: [{ visibility: "off" }]
        },
        {
            featureType: "water",
            elementType: "labels",
            stylers: [{ visibility: "off" }]
        },
        {
            featureType: "road",
            stylers: [{ visibility: "off" }]
        }
    ];
    map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: 0, lng: 0 },
        zoom: z,
        minZoom: 2,
        maxZoom: 18,
        streetViewControl: false,
        mapTypeControl: !ui,
        mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: google.maps.ControlPosition.TOP_CENTER
        },
        disableDefaultUI: ui,
        styles: style
    });
    map.set("styles", mapStyle);
}
function createLocalMap(activeLocation) {
    const lat = activeLocation.latitude ? parseFloat(activeLocation.latitude) : 10;
    const long = activeLocation.longitude ? parseFloat(activeLocation.longitude) : 10;
    map = new google.maps.Map(document.getElementById("map"), {
        center: { lat, lng: long },
        zoom: 4,
        disableDefaultUI: true,
        styles: style
    });
}
function createMarkers() {
    const markerList = [];
    globalLocationList.forEach(location => {
        const url = `https://www.wpi.edu/project-based-learning/project-based-education/global-project-program/project-centers/${location.label.replace(/[\W_]+/g, "-").toLowerCase()}`;
        const lat = location.latitude ? parseFloat(location.latitude) : undefined;
        const long = location.longitude ? parseFloat(location.longitude) : undefined;
        if (lat && long) {
            const marker = {
                id: location.id,
                label: location.label,
                type: location.type,
                active: location.active,
                project_type: location.project_type,
                images: "https://eprojects.wpi.edu" + location.images_scaled_480_x_480,
                latitude: location.latitude,
                longitude: location.longitude,
                images_cropped_480_x_480: location.images_cropped_480_x_480,
                images_scaled_1300_x_1300: location.images_scaled_1300_x_1300,
                images_scaled_480_x_480: location.images_scaled_480_x_480,
                images_scaled_2600_x_2600: location.images_scaled_2600_x_2600,
                region: location.region,
                operational_terms: location.operational_terms,
                directors: location.directors,
                location_and_culture: location.location_and_culture,
                project_opportunities: location.project_opportunities
            };
            markerList.push(marker);
        }
    });
    setMarkers(markerList);
}
function setMarkers(markerList) {
    const mw = 250;
    const y_offset = window.innerHeight / 4;
    const options_click = {
        closeBoxURL: "assets/close.png",
        closeBoxMargin: "5px -40px 0px 0px",
        closeBoxTitle: "Hide info",
        pixelOffset: new google.maps.Size(-(mw / 2), 50),
        maxWidth: mw,
        alignBottom: true
    };
    const options_hover = {
        closeBoxURL: "",
        pixelOffset: new google.maps.Size(15, -50)
    };
    const ib_click = new InfoBox(options_click);
    const ib_hover = new InfoBox(options_hover);
    if (!map) {
        console.error("Google Maps is not initialized.");
        return;
    }
    markerList.forEach((location) => {
        const lat = location.latitude ? parseFloat(location.latitude) : null;
        const lng = location.longitude ? parseFloat(location.longitude) : null;
        const largeIcon = {
            url: "assets/pin.png",
            scaledSize: new google.maps.Size(50, 50)
        };
        const defaultIcon = {
            url: "assets/pin.png",
            scaledSize: new google.maps.Size(32, 32)
        };
        if (lat === null || lng === null) {
            console.warn(`Skipping marker for ${location.label} due to invalid coordinates.`);
            return;
        }
        if (!globalMarkerList.some((e) => { var _a; return ((_a = markerDataMap.get(e)) === null || _a === void 0 ? void 0 : _a.label) === location.label; })) {
            const marker = new google.maps.Marker({
                position: { lat, lng },
                map: map,
                icon: defaultIcon,
                title: location.label,
            });
            markerDataMap.set(marker, location);
            globalMarkerList.push(marker);
            // Marker click behavior
            marker.addListener("click", () => {
                var _a;
                const data = markerDataMap.get(marker);
                ib_hover.close(); // Close hover box when clicked
                // Check marker position and pan the map
                const position = marker.getPosition();
                if (position) {
                    map.panTo(position);
                    let check = document.getElementById("content");
                    if (check.classList.contains("sidebar-active")) {
                        map.panBy(-150, 0);
                    }
                    if (mw < 400) {
                        // shift down by height/4
                        map.panBy(0, -1 * y_offset);
                    }
                    const imgUrl = (data === null || data === void 0 ? void 0 : data.images) || "";
                    const url = `https://www.wpi.edu/project-based-learning/project-based-education/global-project-program/project-centers/${(_a = data === null || data === void 0 ? void 0 : data.label) === null || _a === void 0 ? void 0 : _a.replace(/[\W_]+/g, "-").toLowerCase()}` || "";
                    const content = createInfoContent(imgUrl, (data === null || data === void 0 ? void 0 : data.label) || "", (data === null || data === void 0 ? void 0 : data.location_and_culture) || "", url || "", parameters.active_location == (data === null || data === void 0 ? void 0 : data.id));
                    // Set content and open click InfoBox
                    ib_click.setContent(content);
                    ib_click.open(map, marker);
                    ib_click.show();
                }
            });
            // Map click behavior (only close `ib_click` if the click is outside the marker)
            google.maps.event.addListener(map, "click", (event) => {
                ib_click.close();
                marker.setIcon(defaultIcon);
            });
            // Map drag behavior
            google.maps.event.addListener(map, "drag", () => {
                marker.setIcon(defaultIcon);
                if (ib_click.getVisible()) {
                    ib_click.close();
                }
            });
            //Ensures map pins are set to default when the map cahnges bounds 
            google.maps.event.addListener(map, "bounds_changed", () => {
                marker.setIcon(defaultIcon);
            });
            // Marker hover behavior
            marker.addListener("mouseover", () => {
                marker.setIcon(largeIcon);
                const data = markerDataMap.get(marker);
                if (ib_click.getPosition() != marker.getPosition()) {
                    ib_hover.setContent(createName((data === null || data === void 0 ? void 0 : data.label) || ""));
                    ib_hover.open(map, marker);
                    ib_hover.show();
                }
            });
            // Marker mouseout behavior
            marker.addListener("mouseout", () => {
                if (ib_click.getVisible() && marker.getPosition() == ib_click.getPosition()) {
                    marker.setIcon(largeIcon);
                }
                else {
                    marker.setIcon(defaultIcon);
                }
                ib_hover.close();
            });
        }
    });
}
/*
  Create the content for the infobox
*/
function createInfoContent(img, data_title, info, url, active) {
    // Check if referrer contains wpi.edu
    const match = document.referrer.includes("wpi.edu");
    let learnMore = `<a href="${url}" class="learn-more"${!match ? ' target="_blank"' : 'target="_top"'}>Learn More</a>`;
    let imageBox = `<div class="infobox-img" style="background-image:url('${img}')"></div>`;
    let ib_click = `<div class="ib-click">`;
    if (active) {
        learnMore = "";
        imageBox = "";
        ib_click = `<div class="ib-click-noimg">`;
    }
    if (info) {
        info = info.substring(0, 250) + `...&nbsp;` + learnMore;
        return `${ib_click}${imageBox}<div class="infobox-details">
          <h1 class="infobox-title">${data_title}</h1>
          <p class="info-text">${info}</p>
      </div></div>`;
    }
    else {
        return `${ib_click}${imageBox}<div class="infobox-details">
          <h1 class="infobox-title">${data_title}</h1>${learnMore}
      </div></div>`;
    }
}
function createName(data_title) {
    return `<div class="ib-hover">
        <p>${data_title}</p>
    </div>`;
}
/*
    Create Sidebar and add event listeners on the button, map (for panning).
*/
function createSidebar() {
    const content = document.getElementById("content");
    const sidebarContainer = document.getElementById("sidebar-container");
    const toggleSidebar = document.getElementById("toggle-sidebar");
    if (content && sidebarContainer && toggleSidebar) {
        sidebarContainer.style.display = "flex";
        content.classList.toggle("sidebar-active");
        // Start with sidebar opened, so map shifted
        map.panBy(-125, 0);
        if (window.innerWidth <= 800) {
            // If mobile, start with sidebar closed
            content.classList.toggle("sidebar-active");
            map.panBy(125, 0);
            toggleSidebar.innerHTML = "|||";
        }
        // if sidebar is made, create toggle button and add listener
        toggleSidebar.style.display = "block";
        toggleSidebar.addEventListener("click", () => {
            content.classList.toggle("sidebar-active");
            if (content.classList.contains("sidebar-active")) {
                // Hide
                map.panBy(-125, 0);
                toggleSidebar.innerHTML = "&lt;&lt;";
            }
            else {
                // Show
                map.panBy(125, 0);
                toggleSidebar.innerHTML = "|||";
            }
        });
        const checkboxes = document.getElementsByName("filters");
        checkboxes.forEach((checkbox) => {
            checkbox.addEventListener("click", () => {
                updateData(checkbox.dataset.type || '', checkbox.value, checkbox.checked);
            });
        });
    }
}
function createTopbar() {
    const topbar = document.getElementById("topbar");
    const sidebar = document.getElementById("sidebar-container");
    if (topbar && sidebar) {
        topbar.style.display = "block";
        // edit sidebar height if making topbar
        sidebar.style.height = "calc(100% - 50px)";
        sidebar.style.top = "50px";
    }
}
function finishedLoad() {
    const loader = document.getElementById("loader");
    const content = document.getElementById("content");
    if (loader && content) {
        // Set the duration for the loader animation
        const animationDuration = 1000; // Duration in milliseconds (1 second)
        // Wait for the animation to complete before hiding the loader
        setTimeout(() => {
            loader.style.display = "none";
            content.style.display = "block";
            //Get the index of the active location for the globalMarkerist
            for (let i = 0; i < data.length; i++) {
                if (data[i].id == parameters.active_location) {
                    activeLocationI = i;
                    break;
                }
            }
            // Default local map behavior
            if (parameters.active_location && activeLocationI !== undefined) {
                // Show details on start
                google.maps.event.trigger(globalMarkerList[activeLocationI], "click");
            }
        }, animationDuration); // Wait for the animation duration
    }
}
function deleteMarkers() {
    for (let i = globalMarkerList.length - 1; i >= 0; i--) {
        const marker = globalMarkerList[i];
        const markerData = markerDataMap.get(marker);
        if (markerData && !globalLocationList.some(e => e.id === markerData.id)) {
            // Not in location list
            // Remove from map
            marker.setMap(null);
            // Remove from MarkerList
            globalMarkerList.splice(i, 1);
            // Remove from data map
            markerDataMap.delete(marker);
        }
    }
}
function updateData(key, value, checked) {
    // Make edits to globalMarkerList accordingly
    // create array of checked values
    updateChecks();
    globalLocationList = [];
    data.forEach((location) => {
        if (arrayCompareSome(checksType, location["filter"]) &&
            arrayCompareSome(checksTerm, location["filter"]) &&
            arrayCompareSome(checksRegion, location["filter"])) {
            globalLocationList.push(location);
        }
    });
    // Delete unnecessary markers
    deleteMarkers();
    // Create new markers as needed
    createMarkers();
}
/*
---------------Helper Functions----------------------------------
*/
// Take two arrays and return true if array 1 contains ANY elements of array 2
// Return false otherwise
function arrayCompareSome(arr1, arr2) {
    const check = (ele) => arr1.indexOf(ele.toUpperCase()) > -1;
    return arr2.some(check);
}
// Accept a key to pull from nested into main level
// Add to key 'filter' on main level
function flattenData(key) {
    const val = [];
    data.forEach((location) => {
        if (!("filter" in location)) {
            location["filter"] = [];
        }
        Object.keys(location).forEach(i_key => {
            if (i_key === key) {
                location[i_key] = location[i_key].replace(/\&amp;/g, "&");
                val.push(location[i_key].replace(/\&amp;/g, "&"));
            }
            else if (typeof location[i_key] === "object" &&
                Object.keys(location[i_key]).length > 0) {
                const temp = getVal(location[i_key], key);
                if (temp) {
                    val.push(...temp);
                }
            }
        });
        location["filter"].push(...val);
        val.length = 0; // Clear array
    });
}
// Helper function to flatten json through recursion
// TODO: multiple of the same keys
function getVal(obj, key) {
    const val = [];
    Object.keys(obj).forEach(i_key => {
        if (typeof obj[i_key] === "object" && Object.keys(obj[i_key]).length > 0) {
            const temp = getVal(obj[i_key], key);
            if (temp) {
                val.push(...temp);
            }
        }
        else if (key.localeCompare(i_key, "en", {
            sensitivity: "base"
        }) === 0) {
            val.push(obj[i_key]);
        }
    });
    return val.length > 0 ? val : undefined;
}
// Updates the global variables 'checksType', 'checksTerm', and 'checksRegion' based on the currently checked items
function updateChecks() {
    checksType = [];
    checksTerm = [];
    checksRegion = [];
    const projectTypeCheckboxes = document.querySelectorAll("[data-type='project_type']");
    projectTypeCheckboxes.forEach(input => {
        if (input.checked) {
            checksType.push(input.id.toUpperCase());
        }
    });
    const termCheckboxes = document.querySelectorAll("[data-type='term']");
    termCheckboxes.forEach(input => {
        if (input.checked) {
            checksTerm.push(input.id.toUpperCase());
        }
    });
    const regionCheckboxes = document.querySelectorAll("[data-type='region']");
    regionCheckboxes.forEach(input => {
        if (input.checked) {
            checksRegion.push(input.value.toUpperCase());
        }
    });
}
/*
  MAP STYLES
*/
// This function accepts a style (int) and creates and returns a style sheet.
// 1 = white and red
// 2 = WPI.edu bottom embed map
function styleMap(style) {
    let styles = []; // Initialize with an empty array
    if (style === 1) {
        styles = [
            {
                elementType: "labels",
                stylers: [{ visibility: "off" }]
            },
            {
                featureType: "administrative",
                elementType: "geometry",
                stylers: [{ visibility: "off" }]
            },
            {
                featureType: "administrative.country",
                elementType: "geometry.stroke",
                stylers: [
                    { color: "#fdffff" },
                    { visibility: "on" },
                    { weight: 0.5 }
                ]
            },
            {
                featureType: "administrative.country",
                elementType: "labels.icon",
                stylers: [{ visibility: "on" }]
            },
            {
                featureType: "administrative.country",
                elementType: "labels.text",
                stylers: [
                    { color: "#fdffff" },
                    { lightness: -10 }
                ]
            },
            {
                featureType: "administrative.land_parcel",
                stylers: [{ visibility: "off" }]
            },
            {
                featureType: "administrative.neighborhood",
                stylers: [{ visibility: "off" }]
            },
            {
                featureType: "landscape",
                stylers: [{ color: "#c30f2f" }]
            },
            {
                featureType: "poi",
                stylers: [{ visibility: "off" }]
            },
            {
                featureType: "road",
                stylers: [{ visibility: "off" }]
            },
            {
                featureType: "road",
                elementType: "labels.icon",
                stylers: [{ visibility: "off" }]
            },
            {
                featureType: "transit",
                stylers: [{ visibility: "off" }]
            },
            {
                featureType: "water",
                elementType: "geometry.fill",
                stylers: [{ color: "#fdffff" }]
            }
        ];
    }
    else if (style === 2) {
        styles = [
            {
                elementType: "geometry.stroke",
                stylers: [{ visibility: "off" }]
            },
            {
                elementType: "labels.text",
                stylers: [{ visibility: "off" }]
            },
            {
                featureType: "administrative",
                elementType: "geometry.stroke",
                stylers: [{ color: "#cccbcb" }]
            },
            {
                featureType: "administrative.country",
                elementType: "geometry.stroke",
                stylers: [{ color: "#cccbcb" }]
            },
            {
                featureType: "landscape",
                elementType: "geometry.fill",
                stylers: [{ color: "#cccbcb" }]
            },
            {
                featureType: "landscape",
                elementType: "geometry.stroke",
                stylers: [{ color: "#cccbcb" }]
            },
            {
                featureType: "water",
                elementType: "geometry.fill",
                stylers: [{ color: "#333333" }]
            }
        ];
    }
    else {
        // Optionally handle the default case here or return an empty array
        console.warn(`Style ${style} is not recognized. Returning default styles.`);
        styles = [];
    }
    return styles;
}
